import { useTranslation } from "next-i18next";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "next/image";
import styles from "../styles/header.module.scss";
import { logError } from "../dataProvider/logger";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

export default function Header() {
  const router = useRouter();

  const { t, i18n } = useTranslation();
  const [user, setUser] = useState();

  useEffect(() => {
    async function loadUser() {
      const response = await fetch("/api/user");
      const result = await response.json();
      if (response.ok) {
        setUser(result);
      } else {
        logError(result.message);
      }
    }

    loadUser();
    loadNotification();

    const notificationInterval = setInterval(() => {
      loadNotification();
    }, 300000); // 5 minutes

    return () => clearInterval(notificationInterval);
  }, []);

  async function loadNotification() {
    try {
      const response = await fetch("/api/user/notifications", {
        method: "GET"
      });
      const result = await response.json();

      if (response.ok) {
        for(const notification of result) toast(t(`user.notifications.${notification.translation}`, notification.content), {
          type: notification.type,
          toastId: notification.id,
          onClose: async() => await deleteNotification(notification.id)
        });
      }
    } catch (error) {
      logError(error);
    }
  }

  async function handleLogout() {
    await fetch("/api/logout");
    router.push("/");
  }

  async function deleteNotification(notificationId) {
    try {
      await fetch(`/api/user/notifications/${notificationId}`, {
        method: "DELETE"
      });

    } catch (error) {
      logError(error);
    }
  }

  return (
    <>
      <header className={styles.header}>
        <Navbar expand="lg">
          <Container>
            <Link href="/dashboard" passHref legacyBehavior>
              <Navbar.Brand>
                <div className={styles.logo}>
                  <Image src="/logo.png" height={36} width={50} alt="Logo" />
                </div>
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link href="/user/list" passHref legacyBehavior>
                  <Nav.Link>{t("dashboard.header.contacts")}</Nav.Link>
                </Link>
                <Link href="/user/templates" passHref legacyBehavior>
                  <Nav.Link>{t("dashboard.header.templates")}</Nav.Link>
                </Link>
                <Link href="/user/campaigns" passHref legacyBehavior>
                  <Nav.Link>{t("dashboard.header.campaing")}</Nav.Link>
                </Link>
                <NavDropdown title={t("dashboard.header.tools.this")}>
                  <NavDropdown.Item as={Link} href="/tools/url-reducer">
                    {t("dashboard.header.tools.url-reducer")}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} href="/tools/domains">
                    {t("dashboard.header.tools.domains")}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <NavDropdown title={user ? user.firstName : ""}>
                  <NavDropdown.Item as={Link} href="/user">
                    {t("dashboard.header.profile")}
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} href="/user/billing">
                    {t("dashboard.header.billing")}
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title={i18n.language.toUpperCase()}>
                  {["en", "fr"]
                    .filter((language) => language !== i18n.language)
                    .map((language) => (
                      <NavDropdown.Item
                        as={Link}
                        key={language}
                        href="/dashboard"
                        locale={language}
                      >
                        {language.toUpperCase()}
                      </NavDropdown.Item>
                    ))}
                </NavDropdown>
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <ToastContainer
        limit={3}
        autoClose={false}
        position="top-center"
        theme="colored"
      />
    </>
  );
}
