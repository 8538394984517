import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

export default function Footer() {

    const [showContact, setShowContact] = useState(false)
    const { t } = useTranslation();
    const router = useRouter();

    return (
        <>
        <footer className="d-flex justify-content-center align-items-center mt-auto py-3 bg-light text-center" >
            <div className="mx-2">{`Weave-me ${new Date().getFullYear()}`}</div>
            <div className="mx-2 link-primary text-decoration-underline" onClick={() => setShowContact(true)}>{t("dashboard.footer.about.this")}</div>
            <div className="mx-2"><a href={"/tcs-" + router.locale + ".pdf"} target="_blank" rel="noreferrer">{t("dashboard.footer.about.button-TCs")}</a></div>
        </footer>

    
        <Modal show={showContact} centered={true} onHide={() => setShowContact(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("dashboard.footer.about.this")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className="mb-4">
                        {t("dashboard.footer.about.description", { returnObjects: true}).map(
                        (item, key) => (
                            <p key={key}>{item}</p>
                        ))}
                        <div className="d-flex justify-content-center mt-4 text-center">
                            <div>
                                <a href="mailto:contact@weave-me.com" target="_blank" rel="noreferrer"><button className="btn btn-primary">{t("dashboard.footer.about.button-contact")}</button></a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    </>
    )
}